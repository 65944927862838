import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AppUrl {

  static get TYPE_URL(): string {
    let self = StorageService.getItem('self');
    if (self && self['user_type']) {
      if (self['user_type'] === 'ORGANISATION') {
        return environment.appUrl + 'user/';
      } else if (self['user_type'] === 'ADMIN') {
        return environment.appUrl + 'admin/';
      } else if (self['user_type'] === 'HEAD_COUNSELOR') {
        return environment.appUrl + 'head-counselor/';
      } else if (self['user_type'] === 'COUNSELOR') {
        return environment.appUrl + 'counselor/';
      }
    }
  }

  static get USER_URL(): string {
    return environment.appUrl + 'user/';
  }

  static get ADMIN_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get HEAD_COUNSELOR_URL(): string {
    return environment.appUrl + 'head-counselor/';
  }

  static get COUNSELOR_URL(): string {
    return environment.appUrl + 'counselor/';
  }

  static get LOGIN(): string {
    return AppUrl.USER_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.TYPE_URL + 'self';
  }

  static UPDATE_SELF(): string {
    return AppUrl.TYPE_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.TYPE_URL + 'logout';
  }

  static SEND_OTP(): string {
    return AppUrl.USER_URL + 'send-registration-otp';
  }

  static REGISTER(): string {
    return AppUrl.USER_URL + 'register';
  }


  static FILE_UPLOAD(): string {
    return AppUrl.TYPE_URL + 'file-upload';
  }

  static MULTIPLE_FILE_UPLOAD(): string {
    return AppUrl.TYPE_URL + 'file-multiple-upload';
  }

  static SINGLE_FILE_UPLOAD(): string {
    return AppUrl.TYPE_URL + 'file-single-upload';
  }

  static COUNTRY(): string {
    return AppUrl.TYPE_URL + 'countries';
  }

  static USERS(): string {
    return AppUrl.USER_URL + 'user';
  }

  static STATES(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'states/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'states';
  }

  static INSTITUTION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.TYPE_URL + 'institution/' + uniqueId;
    } else {
      return AppUrl.TYPE_URL + 'institution';
    }
  }

  static INSTITUTION_COUNT(): string {
    return AppUrl.TYPE_URL + 'institution-count';
  }

  static INSTITUTION_COUNTS(): string {
    return AppUrl.TYPE_URL + 'institution-counts';
  }

  static INSTITUTION_WiSE_ADMISSION(): string {
    return AppUrl.TYPE_URL + 'institution-wise-admissions';
  }

  static ACCOUNT_GROWTH(): string {
    return AppUrl.TYPE_URL + 'account-growth';
  }

  static SEGMENTATION_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-segmentation';
  }

  static PROCESSABLE_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-processable';
  }

  static HISTORY_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-history';
  }

  static PROFIT_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-profitability';
  }

  static TOP_ACCOUNT(): string {
    return AppUrl.TYPE_URL + 'accounts-top-account';
  }

  static HOME_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'Landing-overview';
  }

  static COUNSELOR_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'counselor-overview';
  }

  static HEAD_COUNSELOR_COUNTS(): string {
    return AppUrl.TYPE_URL + 'head-counselor-counts';
  }

  static COUNSELOR_COUNTS(): string {
    return AppUrl.TYPE_URL + 'counselor-counts';
  }

  static HEAD_COUNSELOR_ADMISSIONS(): string {
    return AppUrl.TYPE_URL + 'head-counselor-wise-admissions';
  }

  static COUNSELOR_ADMISSIONS(): string {
    return AppUrl.TYPE_URL + 'counselor-wise-admissions';
  }

  static HEAD_COUNSELOR_WISE_SHARING_PERCENTAGE(): string {
    return AppUrl.TYPE_URL + 'head-counselor-wise-sharing-percentage';
  }

  static COUNSELOR_WISE_SHARING_PERCENTAGE(): string {
    return AppUrl.TYPE_URL + 'counselor-wise-sharing-percentage';
  }

  static SESSION_COUNTS(): string {
    return AppUrl.TYPE_URL + 'session-counts';
  }

  static SESSION_WISE_ADMISSION(): string {
    return AppUrl.TYPE_URL + 'session-wise-admissions';
  }

  static PHASES_COUNTS(): string {
    return AppUrl.TYPE_URL + 'phase-counts';
  }

  static STAGE_WISE_REASON(): string {
    return AppUrl.TYPE_URL + 'stage-wise-reasons';
  }

  static EXPENSE_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-expense';
  }

  static CLV_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-clv';
  }

  static ACCOUNT_REVENUE(): string {
    return AppUrl.TYPE_URL + 'account-daily-revenue';
  }

  static CHURN_STUDENT(): string {
    return AppUrl.TYPE_URL + 'account-churn';
  }

  static ACTIVITY_REPORT(): string {
    return AppUrl.TYPE_URL + 'account-activity';
  }

  static STUDENT_OVERVIEW(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.TYPE_URL + 'student-overview/' + uniqueId;
    } else {
      return AppUrl.TYPE_URL + 'student-overview';
    }
  }

  static RECEIPTS_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'receipt-overview';
  }

  static LEAD_TABLE(): string {
    return AppUrl.TYPE_URL + 'lead-wise-table';
  }

  static ASSIGNED_LEADS(): string {
    return AppUrl.TYPE_URL + 'lead-graph';
  }

  static ADMIN_COUNTS(): string {
    return AppUrl.TYPE_URL + 'admin-counts';
  }

  static ADMIN_WISE_ADMISSIONS(): string {
    return AppUrl.TYPE_URL + 'admin-wise-admissions';
  }

  static ADMIN_WISE_INSTITUTIONS(): string {
    return AppUrl.TYPE_URL + 'admin-wise-institutions';
  }

  static TICKET_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'ticket-overview';
  }

  static MODULES_COUNTS(): string {
    return AppUrl.TYPE_URL + 'module-counts';
  }

  static LEAD_COUNTS(): string {
    return AppUrl.TYPE_URL + 'lead-counts';
  }

  static IPCB_WISE_ADMISSIONS(): string {
    return AppUrl.TYPE_URL + 'ipcb-wise-admissions';
  }

  static NOTIFICATION_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'notification-overview';
  }

  static LEAD_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'lead-overview';
  }

  static ACCOUNT_OVERVIEW(): string {
    return AppUrl.TYPE_URL + 'accounts-overview';
  }

  static INSTITUTION_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'institution-status/' + uniqueId;
  }

  static ADMIN(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.TYPE_URL + 'admin/' + uniqueId;
    } else {
      return AppUrl.TYPE_URL + 'admin';
    }
  }

  static ADMIN_COUNT(): string {
    return AppUrl.TYPE_URL + 'admin-count';
  }

  static ADMIN_INSTITUTION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'admin-institutions/' + uniqueId;
  }

  static ASSIGNED_INSTITUTION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-institution/' + uniqueId;
  }

  static ADMIN_ASSIGN_HEAD_COUNSELOR_INSTITUTION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-institution/' + uniqueId;
  }

  static ADMIN_CHANGE_PASSWORD(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'admin-password/' + uniqueId;
  }

  static HEAD_COUNSELOR_CHANGE_PASSWORD(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-password/' + uniqueId;
  }

  static COUNSELOR_CHANGE_PASSWORD(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-password/' + uniqueId;
  }

  static HEAD_COUNSELOR_ASSIGNED_INSTITUTION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-institution/' + uniqueId;
  }

  static COUNSELOR_ASSIGNED_INSTITUTION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-institution/' + uniqueId;
  }

  static COUNSELOR_SHARING_IPCB_PERCENTAGE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-ipcb-mapping/' + uniqueId;
  }

  static ADMIN_STATUS(uniqueId?: any): string {
    return AppUrl.TYPE_URL + 'admin-status/' + uniqueId;
  }

  static PROGRAM(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'program/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'program';
  }

  static PROGRAM_COUNT(): string {
    return AppUrl.TYPE_URL + 'program-count';
  }

  static PROGRAM_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'program-status/' + uniqueId;
  }

  static COURSE(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'course/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'course';
  }

  static COURSE_COUNT(): string {
    return AppUrl.TYPE_URL + 'course-count';
  }

  static COURSE_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'course-status/' + uniqueId;
  }

  static BRANCH(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'branch/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'branch';
  }

  static BRANCH_COUNT(): string {
    return AppUrl.TYPE_URL + 'branch-count';
  }

  static BRANCH_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'branch-status/' + uniqueId;
  }

  static IPCB_MAPPING(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'ipcb-mapping/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'ipcb-mapping';
  }

  static IPCB_MAPPING_COUNT(): string {
    return AppUrl.TYPE_URL + 'ipcb-mapping-count';
  }

  static IPCB_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'ipcb-mapping-status/' + uniqueId;
  }

  static STAGE(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'stage/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'stage';
  }

  static STAGE_COUNT(): string {
    return AppUrl.TYPE_URL + 'stages-count';
  }

  static STAGE_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'stage-status/' + uniqueId;
  }

  static REASON(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'reason/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'reason';
  }

  static REASON_COUNT(): string {
    return AppUrl.TYPE_URL + 'reason-count';
  }

  static REASON_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'reason-status/' + uniqueId;
  }

  static SR_MAPPING(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'sr-mapping/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'sr-mapping';
  }

  static SR_MAPPING_COUNT(): string {
    return AppUrl.TYPE_URL + 'sr-mapping-count';
  }

  static SR_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'sr-mapping-status/' + uniqueId;
  }

  static SESSION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'session/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'session';
  }

  static DEFAULT_SESSION(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.TYPE_URL + 'default-session/' + uniqueId;
    } else {
      return AppUrl.TYPE_URL + 'default-session';
    }
  }

  static SESSION_COUNT(): string {
    return AppUrl.TYPE_URL + 'session-count';
  }

  static SESSION_STATUS(uniqueId: any, status: any): string {
    if (status == 'default_session')
      return AppUrl.TYPE_URL + 'default-session';
    else if (status == 're-registration-session')
      return AppUrl.TYPE_URL + 're-registration-session/' + uniqueId;
    else if (status == 're-registration-entry-session')
      return AppUrl.TYPE_URL + 're-registration-entry-session/' + uniqueId;
    else if (status == 'admission-session')
      return AppUrl.TYPE_URL + 'admission-session/' + uniqueId;
    else if (status == 're-opened-session')
      return AppUrl.TYPE_URL + 're-opened-session/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'session-status/' + uniqueId;
  }

  static SESSION_BY_ID(id: any): string {
    return AppUrl.TYPE_URL + 'session-by-id/' + id;
  }

  static HEAD_COUNSELOR(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'head-counselor/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'head-counselor';
  }

  static HEAD_COUNSELOR_INSTITUTION(uniqueId?: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-institution/' + uniqueId;
  }

  static HEAD_COUNSELOR_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-status/' + uniqueId;
  }

  static HEAD_COUNSELOR_COUNT(): string {
    return AppUrl.TYPE_URL + 'head-counselor-count';
  }

  static SUPPORT_TICKET(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'support-ticket/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'support-ticket';
  }

  static SUPPORT_TICKET_COUNT(): string {
    return AppUrl.TYPE_URL + 'support-ticket-count';
  }

  static SUPPORT_TICKET_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'support-ticket-status/' + uniqueId;
  }

  static SUPPORT_TICKET_IMAGE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'support-ticket-image/' + uniqueId;
  }

  static NOTIFY(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'notify/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'notify';
  }

  static NOTIFY_RECEIVE_COUNT(): string {
    return AppUrl.TYPE_URL + 'unread-notification-count';
  }

  static NOTIFY_COUNT(): string {
    return AppUrl.TYPE_URL + 'notify-count';
  }

  static NOTIFY_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'notify-status/' + uniqueId;
  }

  static COURSE_CURRICULUM(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.TYPE_URL + 'fee-structure/' + uniqueId;
    } else {
      return AppUrl.TYPE_URL + 'fee-structure';
    }

  }

  static COURSE_CURRICULUM_IPCB(): string {
    return AppUrl.TYPE_URL + 'fee-structure-by-ipcb';
  }

  static SUBJECT(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'subject/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'subject';
  }

  static SUBJECT_COUNT(): string {
    return AppUrl.TYPE_URL + 'subject-count';
  }

  static SUBJECT_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'subject-status/' + uniqueId;
  }

  static SUBJECT_SLM(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'subject-slm/' + uniqueId;
  }

  static SUBJECT_MAPPING(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'subject-mapping/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'subject-mapping';
  }

  static SUBJECT_MAPPING_COUNT(): string {
    return AppUrl.TYPE_URL + 'subject-mapping-count';
  }

  static SUBJECT_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'subject-mapping-status/' + uniqueId;
  }

  static COUNSELOR(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'counselor/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'counselor';
  }

  static COUNSELOR_INSTITUTION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-institution/' + uniqueId;
  }

  static COUNSELOR_SHARING_PERCENTAGE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-sharing-percentage/' + uniqueId;
  }

  static COUNSELOR_SHARING_PERCENTAGE_SINGLE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-sharing-percentage-ipcb/' + uniqueId;
  }

  static COUNSELOR_SHARING_PERCENTAGE_ALL(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-sharing-percentage-all/' + uniqueId;
  }

  static HEAD_COUNSELOR_SHARING_PERCENTAGE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-sharing-percentage-ipcb/' + uniqueId;
  }

  static HEAD_COUNSELOR_SHARING_PERCENTAGE_SINGLE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-sharing-percentage-ipcb/' + uniqueId;
  }

  static HEAD_COUNSELOR_SHARING_PERCENTAGE_ALL(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-sharing-percentage-all/' + uniqueId;
  }

  static HEAD_COUNSELOR_SHARING_IPCB_PERCENTAGE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'head-counselor-ipcb-mapping/' + uniqueId;
  }

  static HEAD_COUNSELOR_INSTITUTIONS(uniqueId) {
    return AppUrl.TYPE_URL + 'head-counselor-institution/' + uniqueId;
  }

  static COUNSELOR_COUNT(): string {
    return AppUrl.TYPE_URL + 'counselor-count';
  }

  static COUNSELOR_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'counselor-status/' + uniqueId;
  }

  static MANUAL_FOLLOW_UPS(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'manual-follow-ups/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'manual-follow-ups';
  }

  static FOLLOW_UPS_COUNT(): string {
    return AppUrl.TYPE_URL + 'follow-up-count';
  }

  static STUDENT_LEAD(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'student-lead/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'student-lead';
  }

  static FACEBOOK_LEAD_FORM(facebook_access_token: any): string {
    return AppUrl.TYPE_URL + 'facebook-forms?facebook_access_token=' + facebook_access_token;
  }

  static FACEBOOK_LEAD(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'facebook-lead/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'facebook-lead';
  }

  static MESSENGER_LEAD(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'messenger-lead/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'messenger-lead';
  }

  static FACEBOOK_FOLLOW_UPS(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'facebook-lead-follow-ups/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'facebook-lead-follow-ups';
  }

  static MESSENGER_FOLLOW_UPS(): string {
    return AppUrl.TYPE_URL + 'messenger-lead-follow-ups';
  }

  static INSTAGRAM_FOLLOW_UPS(): string {
    return AppUrl.TYPE_URL + 'instagram-lead-follow-ups';
  }

  static FACEBOOK_LEAD_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'facebook-lead-status/' + uniqueId;
  }

  static MESSENGER_LEAD_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'messenger-lead-status/' + uniqueId;
  }


  static FACEBOOK_LEAD_COUNT(): string {
    return AppUrl.TYPE_URL + 'facebook-lead-count';
  }

  static FACEBOOK_LEAD_ASSIGN(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'facebook-lead-assign/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'facebook-lead-assign';
  }

  static MANUAL_LEAD_ASSIGN(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'manual-lead-assign/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'manual-lead-assign';
  }


  static MESSENGER_LEAD_ASSIGN(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'messenger-lead-assign/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'messenger-lead-assign';
  }

  static INSTAGRAM_LEAD_ASSIGN(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'instagram-lead-assign/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'instagram-lead-assign';
  }

  static INSTAGRAM_LEAD(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'instagram-lead/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'instagram-lead';
  }

  static INSTAGRAM_LEAD_COUNT(): string {
    return AppUrl.TYPE_URL + 'instagram-lead-count';
  }

  static INSTAGRAM_LEAD_STATUS(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'instagram-lead-status/' + uniqueId;
  }

  static WHATSAPP(type: any): string {
    if (type == 'document')
      return AppUrl.TYPE_URL + 'send-whatsapp-document';
    else if (type == 'image')
      return AppUrl.TYPE_URL + 'send-whatsapp-image';
    else if (type == 'template')
      return AppUrl.TYPE_URL + 'whatsapp-template';
    else
      return AppUrl.TYPE_URL + 'send-whatsapp-message';
  }

  static MANUAL_LEAD(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.TYPE_URL + 'manual-lead/' + uniqueId;
    else
      return AppUrl.TYPE_URL + 'manual-lead';
  }

  static MANUAL_LEAD_STATUS(uniqueId?: any): string {
    return AppUrl.TYPE_URL + 'manual-lead-status/' + uniqueId;
  }

  static MANUAL_LEAD_COUNT(): string {
    return AppUrl.TYPE_URL + 'manual-lead-count';
  }

  static STUDENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.TYPE_URL + 'student/' + uniqueId;
    } else {
      return AppUrl.TYPE_URL + 'student';
    }
  }

  static PROCESS_STUDENT(): string {
    return AppUrl.TYPE_URL + 'process-student';
  }

  static VERIFY_STUDENT(): string {
    return AppUrl.TYPE_URL + 'verify-student';
  }

  static STUDENT_COUNT(): string {
    return AppUrl.TYPE_URL + 'student-count';
  }

  static STUDENT_ADD(): string {
    return AppUrl.TYPE_URL + 'add-student';
  }

  static STUDENT_PERSONAL_INFO(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'student-personal-info/' + uniqueId;
  }

  static STUDENT_PHOTOGRAPH(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'student-personal-info-photograph/' + uniqueId;
  }

  static STUDENT_GOVT_PHOTO_ID(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'student-personal-info-govt-photo-id-proof/' + uniqueId;
  }

  static STUDENT_SIGNATURE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'student-personal-info-student-signature/' + uniqueId;
  }

  static STUDENT_ADDRESS_INFO(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'student-address/' + uniqueId;
  }

  static STUDENT_ACADEMIC_DETAIL(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'academic-details/' + uniqueId;
  }

  static STUDENT_TENTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'academic-details-tenth-certificate/' + uniqueId;
  }

  static STUDENT_TWELTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'academic-details-twelth-certificate/' + uniqueId;
  }

  static STUDENT_MORE_QUALIFICATION(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'more-qualification/' + uniqueId;
  }

  static STUDENT_PREVIOUS_CERTIFICATE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'more-qualification-previous-certificate/' + uniqueId;
  }

  static STUDENT_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'more-qualification-graduation-certificate/' + uniqueId;
  }

  static STUDENT_POST_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'more-qualification-post-graduation-certificate/' + uniqueId;
  }

  static STUDENT_OTHER_QUALIFICATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'more-qualification-other-qualification-certificate/' + uniqueId;
  }

  static STUDENT_COURSE_DETAIL(uniqueId: any): string {
    return AppUrl.TYPE_URL + 'course-details/' + uniqueId;
  }

  static RECEIPT(id?: any): string {
    if (id) {
      return AppUrl.TYPE_URL + 'receipt/' + id;
    } else {
      return AppUrl.TYPE_URL + 'receipt';
    }
  }

  static COUNSELORS_RECEIPT(): string {
    return AppUrl.TYPE_URL + 'receipt-counselor';
  }

  static HEAD_COUNSELORS_RECEIPT(): string {
    return AppUrl.TYPE_URL + 'receipt-head-counselor';
  }

  static RECEIPT_UPDATE(unique_id: any): string {
    return AppUrl.TYPE_URL + 'receipt/' + unique_id;
  }


  static SELF_SHARING_PERCENTAGE(): string {
    return AppUrl.TYPE_URL + 'sharing-percentage';
  }

  static PROCESS_TRANSACTION(id: any): string {

    return AppUrl.TYPE_URL + 'process-process_transaction';

  }

  static PROCESS_TRANSACTION_DETAIL(id: any): string {

    return AppUrl.TYPE_URL + 'process-process_transaction-detail/' + id;

  }

  static RECEIPT_TRANSACTION(): string {
    return AppUrl.TYPE_URL + 'receipt-transactions';
  }

  static LEAD_SOURCE_REPORT(): string {
    return AppUrl.TYPE_URL + 'lead-source-report';
  }

  static LEAD_STAGE_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-stage-report?${param}`;
    }
    return AppUrl.TYPE_URL + 'lead-stages-report';
  }

  static LEAD_DISTRIBUTION_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-distribution-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-distribution-report';
    }
  }

  static LEAD_ENGAGEMENT_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-engagement-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-engagement-report';
    }
  }

  static LEAD_AGEING_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-ageing-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-ageing-report';
    }
  }

  static LEAD_DAILY_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-daily-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-daily-report';
    }
  }

  static LEAD_QUALITY_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-quality-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-quality-report';
    }
  }

  static LOST_LEAD_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-lost-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-lost-report';
    }
  }

  static CONVERSION_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-conversion-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-conversion-report';
    }
  }

  static RESPONSE_REPORT(param?: any): string {
    if (param) {
      return AppUrl.TYPE_URL + `lead-response-report?${param}`;
    } else {
      return AppUrl.TYPE_URL + 'lead-response-report';
    }
  }
}

