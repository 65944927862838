import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  faqs = [
    { question: 'What is Payment Gateway?', answer: 'A payment gateway is a technology platform that acts as an intermediary in electronic financial transactions. It enables in-person and online businesses to accept, process, and manage various payment methods—such as credit cards, debit cards, and digital wallets—in a secure and efficient manner.', expanded: false },
    { question: 'What is Payment Gateway?', answer: 'A payment gateway is a technology platform that acts as an intermediary in electronic financial transactions. It enables in-person and online businesses to accept, process, and manage various payment methods—such as credit cards, debit cards, and digital wallets—in a secure and efficient manner.', expanded: false },
    { question: 'What is Payment Gateway?', answer: 'A payment gateway is a technology platform that acts as an intermediary in electronic financial transactions. It enables in-person and online businesses to accept, process, and manage various payment methods—such as credit cards, debit cards, and digital wallets—in a secure and efficient manner.', expanded: false },
    { question: 'What is Payment Gateway?', answer: 'A payment gateway is a technology platform that acts as an intermediary in electronic financial transactions. It enables in-person and online businesses to accept, process, and manage various payment methods—such as credit cards, debit cards, and digital wallets—in a secure and efficient manner.', expanded: false },
    { question: 'What is Payment Gateway?', answer: 'A payment gateway is a technology platform that acts as an intermediary in electronic financial transactions. It enables in-person and online businesses to accept, process, and manage various payment methods—such as credit cards, debit cards, and digital wallets—in a secure and efficient manner.', expanded: false },];

  toggleExpand(faq: any) {
    faq.expanded = !faq.expanded;
  }
}
