import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboard',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/',
            icon: 'mat:home',
            routerLinkActiveOptions: { exact: true },
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'General',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'dropdown',
            label: 'Institutions',
            icon: 'mat:account_balance',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
            children: [
              {
                type: 'link',
                label: 'Overview',
                route: '/general/institutions/overview',
                availableTo: ['ORGANISATION', 'ADMIN']
              },
              {
                type: 'link',
                label: 'Add',
                route: '/general/institutions/add',
                availableTo: ['ORGANISATION']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/institutions/list',
                availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Admins',
            icon: 'mat:admin_panel_settings',
            availableTo: ['ORGANISATION'],
            children: [
              {
                type: 'link',
                label: 'Overview',
                route: '/general/admins/overview',
                availableTo: ['ORGANISATION']
              },
              {
                type: 'link',
                label: 'Add',
                route: '/general/admins/add',
                availableTo: ['ORGANISATION']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/admins/list',
                availableTo: ['ORGANISATION']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Head Counselor',
            icon: 'mat:control_camera',
            availableTo: ['ORGANISATION', 'ADMIN'],
            children: [
              {
                type: 'link',
                label: 'Overview',
                route: '/general/head-counselors/overview',
                availableTo: ['ORGANISATION', 'ADMIN']
              },
              {
                type: 'link',
                label: 'Add',
                route: '/general/head-counselors/add',
                availableTo: ['ADMIN']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/head-counselors/list',
                availableTo: ['ORGANISATION', 'ADMIN']
              },
              {
                type: 'link',
                label: 'Sharing (%)',
                route: '/general/head-counselors/sharing-percentage',
                availableTo: ['ORGANISATION', 'ADMIN']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Counselor',
            icon: 'mat:account_tree',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR'],
            children: [
              {
                type: 'link',
                label: 'Overview',
                route: '/general/counselors/overview',
                availableTo: ['ORGANISATION', 'ADMIN']
              },
              {
                type: 'link',
                label: 'Add',
                route: '/general/counselors/add',
                availableTo: ['ADMIN']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/counselors/list',
                availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR']
              },
              {
                type: 'link',
                label: 'Sharing (%)',
                route: '/general/counselors/sharing-percentage',
                availableTo: ['ORGANISATION', 'ADMIN']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Sessions',
            icon: 'mat:access_time',
            availableTo: ['ORGANISATION', 'ADMIN'],
            children: [
              {
                type: 'link',
                label: 'Overview',
                route: '/general/session/overview',
                availableTo: ['ORGANISATION', 'ADMIN']
              },
              {
                type: 'link',
                label: 'Add',
                route: '/general/session/add',
                availableTo: ['ORGANISATION']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/session/list',
                availableTo: ['ORGANISATION', 'ADMIN']
              }
            ]
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Modules',
        availableTo: ['ORGANISATION', 'ADMIN'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/modules/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Programs',
            route: '/modules/programs',
            icon: 'mat:cast_for_education',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Courses',
            route: '/modules/courses',
            icon: 'mat:how_to_vote',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Branches',
            route: '/modules/branches',
            icon: 'mat:book',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Mappings',
            route: '/modules/mappings',
            icon: 'mat:pin_invoke',
            availableTo: ['ORGANISATION', 'ADMIN']
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Phases',
        availableTo: ['ORGANISATION', 'ADMIN'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/phases/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Stages',
            route: '/phases/stages',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Reasons',
            route: '/phases/reasons',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN']
          },
          {
            type: 'link',
            label: 'Mappings',
            route: '/phases/sr-mappings',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN']
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Leads',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/leads/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Manual',
            route: '/leads/manual',
            icon: 'mat:ballot',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Facebook',
            route: '/leads/facebook',
            icon: 'mat:thumb_up',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Messenger',
            route: '/leads/messenger',
            icon: 'mat:chrome_reader_mode',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Instagram',
            route: '/leads/instagram',
            icon: 'mat:add_a_photo',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Google',
            route: '/leads/google',
            icon: 'mat:g_translate',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },

          {
            type: 'link',
            label: 'Report',
            route: '/leads/leads-report',
            icon: 'mat:assessment',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Students',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/students/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Add Student',
            route: '/students/add-student',
            icon: 'mat:person_add',
            availableTo: ['HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'List Student',
            route: '/students/list-student',
            icon: 'mat:diversity_3',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Verify Students',
            route: '/students/verify-student',
            icon: 'mat:person_search',
            availableTo: ['ADMIN', 'ORGANISATION']
          },
          {
            type: 'link',
            label: 'Process Students',
            route: '/students/process-student',
            icon: 'mat:contact_emergency',
            availableTo: ['HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Re - Register Students',
            route: '/students/re-register-student',
            icon: 'mat:auto_mode',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Transfer Students',
            route: '/students/transfer-student',
            icon: 'mat:cloud_sync',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Accounts',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/accounts/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Process Transaction',
            route: '/accounts/process_transaction',
            icon: 'mat:add_card',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Receipt Transaction',
            route: '/accounts/receipt-process_transaction',
            icon: 'mat:add_card',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Report',
            route: '/accounts/report',
            icon: 'mat:pending_actions',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Receipt',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/receipt/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Add',
            route: '/receipt/add',
            icon: 'mat:post_add',
            availableTo: ['HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'List',
            route: '/receipt/list',
            icon: 'mat:format_align_left',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Counselors Receipt',
            route: '/receipt/counselors',
            icon: 'mat:format_align_left',
            availableTo: ['HEAD_COUNSELOR']
          },
          {
            type: 'link',
            label: 'Head-Counselors Receipt',
            route: '/receipt/head-counselors',
            icon: 'mat:format_align_left',
            availableTo: ['ADMIN']
          }
        ]
      }
      ,
      {
        type: 'subheading',
        label: 'Tickets',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/tickets/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Admission',
            route: '/tickets/admission',
            icon: 'mat:contact_mail',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Re - Registration',
            route: '/tickets/re-registration',
            icon: 'mat:contact_phone',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          },
          {
            type: 'link',
            label: 'Other',
            route: '/tickets/other',
            icon: 'mat:local_library',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR', 'COUNSELOR']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Notification',
        availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR'],
        children: [
          {
            type: 'link',
            label: 'Overview',
            route: '/notification/overview',
            icon: 'mat:dashboard_customize',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR']
          },
          {
            type: 'link',
            label: 'Add',
            route: '/notification/add',
            icon: 'mat:notification_add',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR']
          },
          {
            type: 'link',
            label: 'List',
            route: '/notification/list',
            icon: 'mat:notifications',
            availableTo: ['ORGANISATION', 'ADMIN', 'HEAD_COUNSELOR']
          }
        ]
      }

    ]);
  }
}
