import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {VexPopoverService} from '@vex/components/vex-popover/vex-popover.service';
import {ToolbarUserDropdownComponent} from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {JsonPipe} from "@angular/common";
import {StorageService} from "../../../../shared/services/storage.service";

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatRippleModule,
    MatIconModule,
    JsonPipe
  ],
  providers: [
    StorageService
  ]
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean = false;
  user: any;

  constructor(private popover: VexPopoverService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    if (StorageService.getItem('self')) {
      this.user = StorageService.getItem('self');
    }
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
