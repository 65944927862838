<app-header></app-header>
<div class="container-fluid  bg-about p-5 ">
  <div class="container justify-content-start">
    <div class="box-title text-white text-center mt-20">About Us</div>
    <div>
      <p class="mt-3 font-bold text-white text-4xl ">Discover Our Purpose</p>
    </div>
    <div class="my-10   md:my-20 lg:my-20">
      <button class="px-4 py-3 bg-white text-[#6752A9] rounded">
        <i class="fa fa-briefcase"></i>&nbsp; Add Your Organization
      </button>
    </div>
  </div>
</div>

<div class="container-fluid mt-50px p-5">
  <div class="container">
    <div class="flex flex-col md:flex-row lg:flex-row justify-between ">
      <img src="assets/images/About2.png" alt="Image" class="img-fluid" />
      <div class="ps-3">
        <p class="text-3xl font-medium leading-7">Our Company Overview</p>
        <p class="pt-3 text-justify">Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium
          doloremque
          laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae
          dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit.
          Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
        <p class="pt-3 text-justify">
          Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque
          laudantium
        </p>
        <button class="bg-blue white-color rounded py-3 px-12 my-20 highlight-button font-inter">Learn More</button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-5 sm:mt-10 md:mt-20 lg:mt-20 color-light-blue pb-4">
  <div class="container  p-5">
    <div class="grid justify-items-center grid-cols-2  md:grid-cols-4 lg:grid-cols-4  gap-4">
      <div class="">
        <img src="assets/images/About-cont3-1.png" alt="Image" class="" />
      </div>
      <div class="">
        <img src="assets/images/About-cont3-2.png" alt="Image" class="" />
      </div>
      <div class="">
        <img src="assets/images/About-cont3-3.png" alt="Image" class="" />
      </div>
      <div class="">
        <img src="assets/images/About-cont3-4.png" alt="Image" class="" />
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-50px p-5">
  <div class="container">
    <div class="grid grid-cols-1  gap-12 md:grid-cols-2 lg:grid-cols-2">
      <div class="text-start">
        <p class="text-2xl font-medium leading-7">Our Mission</p>
        <p class="text-justify py-4">Lorem ipsum dolor sit amet, consectetur adipiscing Pharetra Ld eu aliquet diam
          lorem viverra at justo. Nulla
          odio nequefjf gravida in pharetra egestas. Ac id sagittis at morbi interdum nibh diam sagittis et.</p>
        <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing Pharetra id eu aliquet diam lorem
          viverra at
          justo. Nulla odio nequesg gravida in pharetra egestas.</p>
      </div>
      <div class="">
        <img src="assets/images/About-cont4.png" alt="Image" class="" />
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-50px p-5">
  <div class="container">
    <div class="text-center text-3xl font-medium py-4">
      The story and values behind our company
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
      <div class="card card-bg border-none p-3">
        <div class="flex flex-col md:flex-row lg:flex-row items-center">
          <div class="text-start">
            <img src="assets/images/About-cont5-card1.png" alt="Image" class="w-full" />
          </div>
          <div class=" text-start p-4">
            <h1 class="font-inter font-medium text-xl pb-2">Innovation</h1>
            <p>Long established fact that a readeed to will be distracted by the readable content of a page when
              looking at its layout</p>
          </div>
        </div>
      </div>
      <div class="card card-bg border-none p-3">
        <div class="flex flex-col md:flex-row lg:flex-row items-center">
          <div class="text-start">
            <img src="assets/images/About-cont5-card2.png" alt="Image" class="w-full " />
          </div>
          <div class=" text-start p-4">
            <h1 class="font-inter font-medium text-xl pb-2">Team Work</h1>
            <p>Long established fact that a readeed to will be distracted by the readable content of a page when
              looking at its layout</p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 pt-5">
      <div class="card card-bg border-none p-3">
        <div class="flex flex-col md:flex-row lg:flex-row items-center">
          <div class="text-start">
            <img src="assets/images/About-cont5-card3.png" alt="Image" class="w-full" />
          </div>
          <div class=" text-start p-4">
            <h1 class="font-inter font-medium text-xl pb-2">Excellence</h1>
            <p>Long established fact that a readeed to will be distracted by the readable content of a page when
              looking at its layout</p>
          </div>
        </div>
      </div>
      <div class="card card-bg border-none p-3">
        <div class="flex flex-col md:flex-row lg:flex-row items-center">
          <div class="text-start">
            <img src="assets/images/About-cont5-card4.png" alt="Image" class="w-full " />
          </div>
          <div class=" text-start p-4">
            <h1 class="font-inter font-medium text-xl pb-2">Responsibility</h1>
            <p>Long established fact that a readeed to will be distracted by the readable content of a page when
              looking at its layout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-50px">
  <div class="container ">
    <div class="flex justify-center">
      <img src="assets/images/Cont6-Video-thumbnail.png" alt="Image" />
    </div>
  </div>
</div>

<div class="container-fluid mt-100px bg-[#161C2D]">
  <div class="container">
    <div class="grid grid-rows-1 sm:grid-rows-1 md:grid-cols-2 lg:grid-cols-2  ">
      <div class="">
        <img src="assets/images/Chat.png" alt="Image" class="py-5" />
        <h1 class="font-inter text-white text-2xl font-medium">Get a free consultancy from our expert</h1>
        <h1 class="font-inter text-white text-3xl font-bold"> Right Now!</h1>
        <p class="text-white pt-3 pb-20">For athletes, high altitude produces two contradictory effects on performance.</p>
      </div>
      <div class="  md:relative lg:relative ">
        <div class="bg-white px-8  mt-2sm:mt-2  md:mt-20 lg:mt-20 rounded-lg w-full md:absolute lg:absolute ">
          <mat-card-header>
            <form class="p-6 flex flex-col gap-4">
              <div class="flex flex-col">
                <mat-form-field class="flex-1 mt-3" appearance="outline">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="John Doe" required>
                </mat-form-field>
                <mat-form-field class="flex-1 mt-3" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="abctestmail@gmail.com" required>
                </mat-form-field>
                <mat-form-field class="flex-1 mt-3" appearance="outline">
                  <mat-label>Phone Number</mat-label>
                  <input matInput placeholder="+91-7836899999" required>
                </mat-form-field>
                <mat-form-field class="flex-1 mt-3" appearance="outline">
                  <mat-label>Subject</mat-label>
                  <input matInput placeholder="Subject" required>
                </mat-form-field>

                <button class="bg-blue text-white py-3 w-full rounded-none">
                  Book Consultation
                </button>
              </div>
            </form>
          </mat-card-header>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="container">
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 my-10 ">
   <div class="flex flex-col sm:flex-col md:flex-row lg:flex-row  md:justify-around lg:justify-around  gap-y-10 ">
     <div class="flex flex-row justify-center items-center ">
       <div>
         <img src="../../../../assets/images/Phone-icon.png" alt="Image" class=""/>
       </div>
       <div class="ps-3">
         <p class="font-inter text-lg font-bold">Call Us</p>
         <p>+91 9310828501</p>
       </div>
     </div>
     <div class="flex flex-row justify-center items-center">
       <div>
         <img src="../../../../assets/images/Email.png" alt="Image" class=""/>
       </div>
       <div class="ps-3">
         <p class="font-inter text-lg font-bold">Email Us</p>
         <p>support&#64;volmint.com</p>
       </div>
     </div>
   </div>
    <div>

    </div>
  </div>
</div>

<div class="container mt-10  md:mt-40 lg:mt-40 ">
  <div class=" p-5">
    <p class="text-center text-3xl font-medium font-inter leading-7">
      Frequently Asked Questions
    </p>
    <p class="text-center mt-3">
      Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
      most promising candidates first.
    </p>
  </div>
  <div class="text-left mt-10px max-w-3xl mx-auto">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Does this app offer a free trial period?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>What payment methods do you offer?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>How much does a subscription cost?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>What is your refund policy?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="above-footer mt-10 py-5 ">
  <div class="container">
    <div class="m-auto text-center">
      <img src="./assets/images/img-group.png" alt="" class="mx-auto">
      <h3 class="mt-5 text-white text-xl font-medium">
        Need any further assistance?
      </h3>
      <p class="mt-4 text-white ">
        Feel free to reach out for any inquiries or assistance.
      </p>
      <button type="button" class="mt-5 px-3 py-2 bg-white text-[#6752A9] rounded">
        Book an appointment now
      </button>
    </div>
  </div>
</div>
<app-footer></app-footer>

