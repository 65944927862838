<!-- Navbar Container -->
<nav class="bg-white p-4 flex items-center justify-between">
  <!-- Logo -->
  <div class="flex items-center justify-between lg:justify-center w-full lg:w-auto">
    <img src="./assets/images/brand/logo.png" alt="Logo" class="h-8 w-auto">

      <mat-icon class="icon-sm lg:hidden text-black pr-7" svgIcon="mat:menu" (click)="toggleMenu()"></mat-icon>

  </div>


  <!-- Navigation Links -->
  <div class="hidden lg:flex  lg: items-center space-x-4">
    <a href="/about-us" class="text-black   ">About Us</a>
    <a href="/pricing-plans" class="text-black   ">Pricing & Plans</a>
    <a href="/our-services" class="text-black   ">Services</a>
    <a href="/features" class="text-black   ">Features</a>
    <a href="/resources" class="text-black   ">Resources</a>
    <a href="/contact-sales" class="bg-blue text-white rounded-md  px-3 py-2 flex items-center">
      Contact Sales
      <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
    </a>
  </div>
  <!-- Responsive Menu -->
  <div *ngIf="this.isMenuOpen" class="lg:hidden bg-white mt-10 space-y-2  z-40 absolute " >
    <a href="/about-us" class="block text-gray-700 bg-gray-200 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200 rounded-lg px-4 py-2">
      About Us
    </a>
    <a href="/pricing-plans" class="block text-gray-700 bg-gray-200 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200 rounded-lg px-4 py-2">
      Pricing & Plans
    </a>
    <a href="/our-services" class="block text-gray-700 bg-gray-200 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200 rounded-lg px-4 py-2">
      Services
    </a>
    <a href="/features" class="block text-gray-700 bg-gray-200 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200 rounded-lg px-4 py-2">
      Features
    </a>
    <a href="/resources" class="block text-gray-700 bg-gray-200 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200 rounded-lg px-4 py-2">
      Resources
    </a>
    <a href="/contact-sales" class=" bg-blue-500 text-white hover:bg-blue-600 transition-all duration-200 rounded-lg px-4 py-2 flex items-center justify-center">
      Contact Sales
      <mat-icon class="icon-sm ml-2" svgIcon="mat:phone"></mat-icon>
    </a>
  </div>

</nav>
