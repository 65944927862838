<!--<div class="card flex flex-col sm:flex-row">
<div class="p-6 md:min-w-[300px] flex-none">

</div>
</div>-->
<div class="container">
  <div class="flex-auto flex items-center container">
    <div class=" py-4 grid grid-cols-1 sm:grid-cols-4 gap-3">
      <div class="py-3  items-center">
        <div
          class="ltr:mr-3 rtl:ml-3  items-center justify-center">
          <img alt="Logo" src="./assets/images/brand/logo.png" />
          <h6 class="py-5">Partner   with us for reliable and cost-effective Management services that keep your business running smoothly.</h6>
          <div>
            <p class="mt-5 mb-4 follow">
              Follow Us On
            </p>
            <div class="flex ">
              <a href="">
                <img src="../../../assets/images/twitter-landing.png" class="me-2" alt="">
              </a>
              <a href="">
                <img src="../../../assets/images/insta-landing.png" class="me-2" alt="">
              </a>
              <a href="">
                <img src="../../../assets/images/linkedin-landing.png" class="me-2" alt="">
              </a>
              <a href="">
                <img src="../../../assets/images/facebook-landing.png" class="me-2" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3  items-center">
        <div
          class="ltr:mr-3 rtl:ml-3  items-center justify-center">
          <h3 class="mb-3 title text-center ">Company</h3>
          <mat-list class="list text-center">
            <mat-list-item>
              <h3>Who We Are</h3>
            </mat-list-item>
            <mat-list-item>
              <h3>Pricing & Plans</h3>
            </mat-list-item>
            <mat-list-item>
              <h3>Services</h3>
            </mat-list-item>
            <mat-list-item>
              <h3>Features</h3>
            </mat-list-item>
            <a  mat-list-item>
              <h4 matListItemTitle>Refund Policy</h4>
            </a>
          </mat-list>
        </div>
      </div>
      <div class="py-3  items-center">
        <div
          class="ltr:mr-3 rtl:ml-3  items-center justify-center">
          <h3 class="mb-3 title text-center ">Resources</h3>
          <mat-list class="list text-center">
            <mat-list-item>
              <h3>FAQ’s</h3>
            </mat-list-item>
            <mat-list-item>
              <h3>Blogs</h3>
            </mat-list-item>
            <mat-list-item>
              <h3>Contact Us</h3>
            </mat-list-item>
            <mat-list-item>
              <h3>Terms & Conditions</h3>
            </mat-list-item>
            <a  mat-list-item>
              <h4 matListItemTitle>Privacy Statement</h4>
            </a>
          </mat-list>
        </div>
      </div>
      <div class="py-3   ">
        <div
          class="ltr:mr-3  rtl:ml-3 my-4  flex  ">
          <div
            class="w-10 h-10 rounded-full bg-primary-600/10 text-primary-600 ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
          </div>

          <div>
            <p class="m-0 body-1">Call Us</p>
            <p class="m-0 caption text-hint">+91 9310828501</p>
          </div>
        </div>
        <div
          class="ltr:mr-3 rtl:ml-3 my-4 flex  ">
          <div
            class="w-10 h-10 rounded-full bg-primary-600/10 text-primary-600 ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:mail"></mat-icon>
          </div>

          <div>
            <p class="m-0 body-1">E-Mail Us</p>
            <p class="m-0 caption text-hint">+91 9310828501</p>
          </div>
        </div>
        <div
          class="ltr:mr-4 rtl:ml-4 my-4 flex  ">
          <div
            class="w-10 h-10 rounded-full bg-primary-600/10 text-primary-600 ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:location_on"></mat-icon>
          </div>

          <div>
            <p class="m-0 body-1">29/2,Vijay Enclave,<br>Dwarka-Palam Road, <br> Delhi-110045</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="copyright mp-0">
  <p class="mp-0 text-center py-3">
    © 2024 Copyright, All Right Reserved, Made by Volmint Technologies
  </p>
</div>


