import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path :'',
    loadChildren:()=> import('../../layouts/components/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'general/institutions',
    loadChildren: () => import('../../layouts/components/institution/institution.module').then(m => m.InstitutionModule)
  },
  {
    path: 'general/admins',
    loadChildren: () => import('../../layouts/components/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'general/head-counselors',
    loadChildren: () => import('../../layouts/components/head-counselor/head-counselor.module').then(m => m.HeadCounselorModule)
  },
  {
    path: 'general/counselors',
    loadChildren: () => import('../../layouts/components/counselor/counselor.module').then(m => m.CounselorModule)
  },
  {
    path: 'general/session',
    loadChildren: () => import('../../layouts/components/session/session.module').then(m => m.SessionModule)
  },
  {
    path: 'modules',
    loadChildren : () => import('../../layouts/components/modules/modules.module').then(m => m.ModulesModule)
  },
  {
    path: 'phases',
    loadChildren: () => import('../../layouts/components/phases/phases.module').then(m => m.PhasesModule)
  },

  {
    path: 'leads',
    loadChildren: () => import('../../layouts/components/lead/lead.module').then(m => m.LeadModule)
  },
  {
    path: 'students',
    loadChildren: () => import('../../layouts/components/student/student.module').then(m => m.StudentModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('../../layouts/components/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('../../layouts/components/ticket/ticket.module').then(m => m.TicketModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('../../layouts/components/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('../../layouts/components/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('../../layouts/components/receipt/receipt.module').then(m => m.ReceiptModule)
  }

];

export const landing: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('../../landing/Pages/Landing/Landing.module').then(m => m.LandingModule)
  }
]
