import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-Landing',
  templateUrl: './Landing.component.html',
  styleUrls: ['./Landing.component.scss']
})
export class LandingComponent implements OnInit {
  selectedIndex = 0;
  interval: any;
  constructor(private router: Router,) {
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.selectedIndex = (this.selectedIndex + 1) % 4; // Cycle through 0, 1, 2
    }, 3000);
  }

  functions: any = [
    {
      icon: 'fa-regular fa-circle-check',
      content: 'Track attendance with ease',
    },
    {
      icon: 'fa-regular fa-circle-check',
      content: 'Schedule shifts effortlessly',
    },
    {
      icon: 'fa-regular fa-circle-check',
      content: 'Track days off efficiently',
    },
    {
      icon: 'fa-regular fa-circle-check',
      content: 'Convert time to timesheets',
    }
  ];

  openOrganizaton() {
    this.router.navigateByUrl('organization');
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
