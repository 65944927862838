<app-header></app-header>
<div class="container-fluid  bg-services p-5 ">
  <div class="container justify-content-start">
    <div class="box-title text-white text-center mt-20">SERVICES</div>
    <div>
      <p class="mt-3 font-bold text-white text-4xl">Experience Excellence with Enroll's <br>Comprehensive Services</p>
    </div>
    <div class="my-10   md:my-20 lg:my-20">
      <button class="px-4 py-3 bg-white text-[#6752A9] rounded">
        <i class="fa fa-briefcase"></i>&nbsp; Add Your Organization
      </button>
    </div>
  </div>
</div>
<div class="container-fluid mt-50px p-5">
  <div class="container">
    <div class="flex flex-col md:flex-row lg:flex-row justify-between ">
      <img src="assets/images/our-serices.png" alt="Image" class="" />
      <div class="ps-3">
        <p class="text-3xl font-medium leading-7">Our Services</p>
        <p class="pt-3 text-justify">At Enroll, we revolutionize the way institutions manage their admissions process.
          Our comprehensive software solutions streamline every aspect of admissions, from initial inquiry to final
          enrollment, ensuring a seamless and efficient experience for both administrators and applicants. With our
          advanced tools, you can enhance your recruitment strategies, manage leads effectively, and integrate sales
          processes, all within one powerful platform.
        </p>
        <button class="bg-blue white-color rounded-none py-3 px-12 my-20 highlight-button font-inter">Learn More
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid  mt-10 md:mt-20 lg:mt-20 p-5">
  <div class="container">
    <div class="text-center py-5">
      <p class="text-3xl font-inter leading-7 font-medium">Empower Your Institution with Enroll’s Premier Services</p>
    </div>
    <div class="flex flex-col md:flex-row lg:flex-row justify-between  mt-8 md:mt-20 lg:mt-20">
      <div>
        <img src="assets/images/Services1.png" alt="Image" class="" />
        <p class="font-['Sora'] font-bold text-xl leading-5 text-['#111827'] py-3">Streamlined Admission Management</p>
        <p class="md:w-3/5 lg:w-3/5 text-['#374151']">Manage the admissions process, maintain a record of applications,
          improve communication, and use data to learn more.</p>
        <p class=" py-4 flex"><img src="assets/images/services-tick.png" alt=""> <span
          class="text-['#1F2937'] px-3 text-base">Simplify and automate your entire admissions cycle</span></p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span
          class="text-['#1F2937'] px-3 text-base">Track and manage applications effortlessly from start to finish</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span
          class="text-['#1F2937'] px-3 text-base">Enhance applicant communication with personalized messaging</span></p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span
          class="text-['#1F2937'] px-3 text-base">Gain insights with detailed analytics and reporting tools</span>
        </p>
        <button class="bg-blue text-white rounded py-3 px-12 my-10 highlight-button font-inter">
          Get Free Demo <i class="fa fa-desktop px-2"></i>
        </button>
      </div>
      <div>
        <img src="assets/images/ams-service.png" alt="Image" class="" />
      </div>
    </div>
    <div class="flex flex-col md:flex-row  mt-8 md:mt-20 lg:mt-20">
      <div class="order-2 md:order-1 lg:order-1 basis-1/2">
        <img src="assets/images/lead-services.png" alt="Image" class="" />
      </div>
      <div class="order-1 md:order-2 lg:order-2 basis-1/2">
        <img src="assets/images/Services2.png" alt="Image" class="" />
        <p class="font-['Sora'] font-bold text-xl leading-5 text-['#111827'] py-3">Dynamic Social Media Lead
          Management</p>
        <p class="md:w-3/5 lg:w-3/5 text-['#374151']">Using social media to collect leads, interact with prospects
          through focused campaigns, combine data into CRM, and track the success of marketing.</p>
        <p class=" py-4 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Capture and nurture lead from all major social media platforms</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Engage prospective students with targeted campaigns</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Integrate social media data seamlessly into your CRM</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Monitor and analyze the effectiveness of your social outreach</span>
        </p>
        <button class="bg-blue text-white rounded py-3 px-12 my-10 highlight-button font-inter">
          Get Free Demo <i class="fa fa-desktop px-2"></i>
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row lg:flex-row justify-between mt-8 md:mt-20 lg:mt-20">
      <div>
        <img src="assets/images/Services3.png" alt="Image" class="" />
        <p class="font-['Sora'] font-bold text-xl leading-5 text-['#111827'] py-3">Facile Sales Integration
          Management</p>
        <p class="md:w-3/5 lg:w-3/5 text-['#374151']">Improve recruiting for students via smooth teamwork, effective
          processes, and increased sales conversions.</p>
        <p class=" py-4 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Align your admissions and sales teams for better collaboration</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Streamline workflows with integrated sales and enrollment processes</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Track and manage prospective student interactions from inquiry to enrollment</span>
        </p>
        <p class=" py-3 flex"><img src="assets/images/services-tick.png" alt=""> <span class="text-['#1F2937'] px-3 ">Boost conversion rates with efficient sales funnel management</span>
        </p>
        <button class="bg-blue text-white rounded py-3 px-12 my-10 highlight-button font-inter">
          Get Free Demo <i class="fa fa-desktop px-2"></i>
        </button>
      </div>
      <div>
        <img src="assets/images/sales-services.png" alt="Image" class="" />
      </div>
    </div>
  </div>
</div>
<div class="container-fluid  p-5">
  <div class="container mx-auto p-5">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div>
        <mat-card>
          <div class="bg-fuchsia-200 rounded p-5">
            <div class="flex justify-between items-center">
              <mat-card-title class="font-bold font-['var(--vex-font)']">Individual Plan</mat-card-title>
              <img mat-card-image src="assets/images/services-price-icon.png" style="width:18%">
            </div>
          </div>
          <mat-card-content class="bg-fuchsia-50">
            <ul class="px-5 list-disc">
              <li class="p-2">Modules Included</li>
              <li class="p-2">Android, iOS App</li>
              <li class="p-2">Unlimited Auto-Emails & App Messages</li>
              <li class="p-2">Onboarding training</li>
              <li class="p-2">IVR Calling Support</li>
              <li class="p-2">Automated Reports and Analytics</li>
              <li class="p-2">Add and Manage Multiple Institutions</li>
              <li class="p-2">Manage Multiple Counselor</li>
              <li class="p-2">24/7 Customer Support</li>
              <li class="p-2">Payment Gateway Integration Support</li>
            </ul>
          </mat-card-content>
          <mat-card-actions class="bg-fuchsia-50">
            <button class="p-5 text-base font-medium">
              Learn More <i class="fa fa-arrow-right"></i>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div>
        <mat-card>
          <div class="bg-fuchsia-200 rounded p-5">
            <div class="flex justify-between items-center">
              <mat-card-title class="font-bold font-['var(--vex-font)']">Combo Plan</mat-card-title>
              <img mat-card-image src="assets/images/services-price-icon.png" style="width:18%">
            </div>
          </div>
          <mat-card-content class="bg-fuchsia-50">
            <ul class="px-5 list-disc">
              <li class="p-2">Modules Included</li>
              <li class="p-2">Android, iOS App</li>
              <li class="p-2">Unlimited Auto-Emails & App Messages</li>
              <li class="p-2">Onboarding training</li>
              <li class="p-2">IVR Calling Support</li>
              <li class="p-2">Automated Reports and Analytics</li>
              <li class="p-2">Add and Manage Multiple Institutions</li>
              <li class="p-2">Manage Multiple Counselor</li>
              <li class="p-2">24/7 Customer Support</li>
              <li class="p-2">Payment Gateway Integration Support</li>
              <li class="p-2">Access to Manage leads from social media</li>
              <li class="p-2">Target Campaigns</li>
            </ul>
          </mat-card-content>
          <mat-card-actions class="bg-fuchsia-50">
            <button class="p-5 text-base font-medium">
              Learn More <i class="fa fa-arrow-right"></i>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div>
        <mat-card>
          <div class="bg-fuchsia-200 rounded p-5">
            <div class="flex justify-between items-center">
              <mat-card-title class="font-bold font-['var(--vex-font)']">Full Package</mat-card-title>
              <img mat-card-image src="assets/images/services-price-icon.png" style="width:18%">
            </div>
          </div>
          <mat-card-content class="bg-fuchsia-50">
            <ul class="px-5 list-disc">
              <li class="p-2">Modules Included</li>
              <li class="p-2">Android, iOS App</li>
              <li class="p-2">Unlimited Auto-Emails & App Messages</li>
              <li class="p-2">Onboarding training</li>
              <li class="p-2">IVR Calling Support</li>
              <li class="p-2">Automated Reports and Analytics</li>
              <li class="p-2">Add and Manage Multiple Institutions</li>
              <li class="p-2">Manage Multiple Counselor</li>
              <li class="p-2">24/7 Customer Support</li>
              <li class="p-2">Payment Gateway Integration Support</li>
              <li class="p-2">Access to Manage leads from social media</li>
              <li class="p-2">Target Campaigns</li>
              <li class="p-2">Streamline Workflow</li>
              <li class="p-2">Sales Funnel Management</li>
              <li class="p-2">Track and Manage Prospective Leads</li>
            </ul>
          </mat-card-content>
          <mat-card-actions class="bg-fuchsia-50">
            <button class="p-5 text-base font-medium">
              Learn More <i class="fa fa-arrow-right"></i>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid  p-5 bg-sky-100">
  <div class="container mx-auto ">
    <h3 class="text-2xl font-medium leading-7 text-center font-['var(--vex-font)' py-4">Benefit of Our AMS</h3>
    <p class="text-sm text-zinc-500 text-center md:indent-64">Enroll's advanced Admission Management System (AMS)
      enhances
      admissions efficiency and success by streamlining
      workflows, improving communication, and providing data-driven insights.</p>
    <div class="grid  grid-cols-1 md:grid-cols-3 gap-8 my-4">
      <div>
        <mat-card class="shadow">
          <mat-card-header>
            <img mat-card-avatar src="assets/images/benifits-ams.png">
          </mat-card-header>
          <mat-card-content>
            <p class="font-medium text-lg pb-5">Automated Online Software System</p>
            <p class="text-justify text-sm text-zinc-400">
              Revolutionize your admissions with our fully automated software, streamlining every process and operation
              through integrated solutions.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div>
        <mat-card class="shadow">
          <mat-card-header>
            <img mat-card-avatar src="assets/images/benifits-ams.png">
          </mat-card-header>
          <mat-card-content>
            <p class="font-medium text-lg pb-5">Manpower Efficiency</p>
            <p class="text-justify text-sm text-zinc-400">
              Save on resources by eliminating the need for additional staff to manage large crowds and repetitive
              tasks, ensuring smooth and secure operations.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div>
        <mat-card class="shadow">
          <mat-card-header>
            <img mat-card-avatar src="assets/images/benifits-ams.png">
          </mat-card-header>
          <mat-card-content>
            <p class="font-medium text-lg pb-5">Boost Productivity</p>
            <p class="text-justify text-sm text-zinc-400">
              Maximize your institution's potential by reallocating manpower to more impactful activities, thanks to our
              efficient online admission process.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid  mt-20 p-5 ">
  <div class="container">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-7">
      <div>
        <img src="assets/images/how-we-works.png" alt="How We Works" class="w-full h-auto max-w-full">
      </div>
      <div class="md:ps-10">
        <h3 class="text-2xl font-medium leading-7 font-['var(--vex-font)' py-4  mb-5">How We Works</h3>
        <div class="flex flex-row mb-8">
          <div class="flex-none">
            <img src="assets/images/how-one.png" alt="One" class="px-4">
          </div>
          <div class="grow">
            <p class="text-lg font-medium py-4">Understanding Your Needs</p>
            <p class="text-sm text-zinc-400 text-justify ">We start by getting to know your institution's unique
              requirements and challenges. Through detailed
              consultations, we gather insights to tailor our solutions specifically to your admissions process,
              ensuring we address all your pain points effectively.</p>
          </div>
        </div>
        <div class="flex flex-row mb-8">
          <div class="flex-none">
            <img src="assets/images/how-two.png" alt="One" class="px-4">
          </div>
          <div class="grow">
            <p class="text-lg font-medium py-4">Customizing Our Solutions</p>
            <p class="text-sm text-zinc-400 text-justify ">Once we understand your needs, we customize our powerful
              admission management software to fit seamlessly into your existing workflows. Our goal is to provide an
              integrated system that enhances efficiency and simplifies your operations.</p>
          </div>
        </div>
        <div class="flex flex-row mb-8">
          <div class="flex-none">
            <img src="assets/images/how-three.png" alt="One" class="px-4">
          </div>
          <div class="grow">
            <p class="text-lg font-medium py-4">Implementing with Precision</p>
            <p class="text-sm text-zinc-400 text-justify ">Our expert team handles the implementation process with
              precision, ensuring minimal disruption to your daily operations. We provide comprehensive training to your
              staff, empowering them to utilize the system to its fullest potential from day one.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="flex-none">
            <img src="assets/images/how-four.png" alt="One" class="px-4">
          </div>
          <div class="grow">
            <p class="text-lg font-medium py-4">Ongoing Support and Optimization</p>
            <p class="text-sm text-zinc-400 text-justify ">At Enroll, our commitment doesn't end with implementation. We
              offer continuous support and regular updates to keep your system running smoothly. We also work with you
              to optimize the system over time, ensuring it grows and adapts to your institution's evolving needs.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-100px bg-[#161C2D]">
  <div class="container">
    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  ">
      <div class="">
        <img src="assets/images/Chat.png" alt="Image" class="py-5" />
        <h1 class="font-inter text-white text-2xl font-medium">Get a free consultancy from our expert</h1>
        <h1 class="font-inter text-white text-3xl font-bold"> Right Now!</h1>
        <p class="text-white pt-3 pb-20">For athletes, high altitude produces two contradictory effects on
          performance.</p>
      </div>
      <div class="  md:relative lg:relative ">
        <div class="bg-white px-8  mt-2sm:mt-2  md:mt-20 lg:mt-20 rounded-lg w-full md:absolute lg:absolute ">
          <form class="p-6 flex flex-col gap-4">
            <div class="flex flex-col">
              <mat-form-field class="flex-1 mt-3" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="John Doe" required>
              </mat-form-field>
              <mat-form-field class="flex-1 mt-3" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="abctestmail@gmail.com" required>
              </mat-form-field>
              <mat-form-field class="flex-1 mt-3" appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput placeholder="+91-7836899999" required>
              </mat-form-field>
              <mat-form-field class="flex-1 mt-3" appearance="outline">
                <mat-label>Subject</mat-label>
                <input matInput placeholder="Subject" required>
              </mat-form-field>

              <button class="bg-blue text-white py-3 w-full rounded-none">
                Book Consultation
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="container">
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 my-10 ">
    <div class="flex flex-col sm:flex-col md:flex-row lg:flex-row  md:justify-around lg:justify-around  gap-y-10 ">
      <div class="flex flex-row justify-center items-center ">
        <div>
          <img src="../../../../assets/images/Phone-icon.png" alt="Image" class="" />
        </div>
        <div class="ps-3">
          <p class="font-inter text-lg font-bold">Call Us</p>
          <p>+91 9310828501</p>
        </div>
      </div>
      <div class="flex flex-row justify-center items-center">
        <div>
          <img src="../../../../assets/images/Email.png" alt="Image" class="" />
        </div>
        <div class="ps-3">
          <p class="font-inter text-lg font-bold">Email Us</p>
          <p>support&#64;volmint.com</p>
        </div>
      </div>
    </div>
    <div>

    </div>
  </div>
</div>

<div class="container mt-10  md:mt-40 lg:mt-40 ">
  <div class=" p-5">
    <p class="text-center text-3xl font-medium font-inter leading-7">
      Frequently Asked Questions
    </p>
    <p class="text-center mt-3">
      Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
      most promising candidates first.
    </p>
  </div>
  <div class="text-left mt-10px max-w-3xl mx-auto">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>What is the scope of Enroll's Admission Management System?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Enroll’s AMS covers every aspect of the admissions process, from application submission and tracking to final
          enrollment. Our comprehensive solution ensures a seamless experience for both administrators and applicants,
          streamlining operations and improving efficiency.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>How does Enroll's Social Media Lead Management work?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Our Social Media Lead Management tool captures and nurtures leads from major social media platforms. It allows
          you to engage prospective students with targeted campaigns and seamlessly integrate social media data into
          your CRM, enhancing your recruitment efforts.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Can Enroll’s system integrate with our existing sales tools?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Yes, Enroll’s Sales Integration Management is designed to work seamlessly with your current sales tools. It
          aligns your admissions and sales teams, streamlining workflows and ensuring a cohesive approach to managing
          prospective student interactions from inquiry to enrollment.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>What kind of support does Enroll provide post-implementation?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Enroll offers comprehensive ongoing support and regular updates to ensure your system runs smoothly. Our
          dedicated team is available to assist with any issues, provide training, and help optimize the system as your
          institution’s needs evolve.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="above-footer mt-10 py-5 ">
  <div class="container">
    <div class="m-auto text-center">
      <img src="./assets/images/img-group.png" alt="" class="mx-auto">
      <h3 class="mt-5 text-white text-xl font-medium">
        Need any further assistance?
      </h3>
      <p class="mt-4 text-white ">
        Feel free to reach out for any inquiries or assistance.
      </p>
      <button type="button" class="mt-5 px-3 py-2 bg-white text-[#6752A9] rounded">
        Book an appointment now
      </button>
    </div>
  </div>
</div>

<app-footer></app-footer>
