import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  static getItem(key: any) {
    // @ts-ignore
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
  }

  static setItem(key: any, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: any) {
    localStorage.removeItem(key);
  }

  static clearAll() {
    localStorage.clear();
  }

  static setUserToken(token: any, tokenType: any) {
    localStorage.setItem('token', token);
    localStorage.setItem('tokenType', tokenType);
  }

  constructor() {
  }

  public setUser(user: any) {
    localStorage.setItem('user', user);
  }
}
