import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {VexPageLayoutComponent} from "@vex/components/vex-page-layout/vex-page-layout.component";
import {VexSecondaryToolbarComponent} from "@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component";
import {VexBreadcrumbsComponent} from "@vex/components/vex-breadcrumbs/vex-breadcrumbs.component";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {ErrorStateMatcher, MatOptionModule, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import { NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {StorageService} from "../../shared/services/storage.service";
import {AuthService} from "../../shared/services/auth.service";
import {GlobalService} from "../../shared/services/global.service";
import {UserService} from "../../shared/services/user.service";
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'vex-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    VexPageLayoutComponent,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    MatIconModule,
    VexBreadcrumbsComponent,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgIf,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgFor,
    MatSlideToggleModule,
    MatTooltipModule,
    NgOptimizedImage
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ],
  providers: [
    GlobalService,
    AuthService,
    UserService,
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  signInForm: FormGroup = new FormGroup({});
  otpForm: FormGroup = new FormGroup({});
  inputType = 'password';
  visible = false;
  submitted: any = false;
  isBusy: any = false;
  organisations: any = [];
  formType: string = "login";
  signInParams: any={};
  issubmitting:boolean = false;

  constructor(private router: Router,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private snackbar: MatSnackBar,
              private authService: AuthService,
              private userService: UserService,
              private globalService: GlobalService,
              private route: ActivatedRoute) {
    this.checkLogin();
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.loginForm = this.fb.group({
      'organisation_id': ['', Validators.required],
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    });
    this.signInForm = this.fb.group({
      'name': ['', Validators.required],
      'email': ['', Validators.required],
      'contact': ['', Validators.required],
      'organisation_name': ['', Validators.required],
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });
    this.otpForm = this.fb.group({
      'otp': ['', Validators.required],
    });
    this.getUsers();
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
        this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type,
        'organisation_id': data.organisation_id,
      });
      this.router.navigateByUrl('/');
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }


  getUsers(): void {
    this.userService.get().subscribe(data => {
      if (data && data['data'].length) {
        this.organisations = data['data'];
      }
    });
  }

  login() {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.submitted) {
      if (this.checkLoggedInStatus()) {
        this.setSelfData();
        this.router.navigateByUrl('/');
      } else {
        let params = {
          'username': this.loginForm.getRawValue().username,
          'password': this.loginForm.getRawValue().password,
          'organisation_id': this.loginForm.getRawValue().organisation_id
        };
        this.authService.login(params).subscribe(data => {
          if (data.id) {
            this.globalService.setAccessToken(data.token);
            this.globalService.setSelf({
              'name': data.name,
              'email': data.email,
              'contact': data.contact,
              'user_id': data.id,
              'username': data.username,
              'user_type': data.user_type,
              'organisation_id': data.organisation_id,
              'organisation_name': data.organisation_name
            });
            this.router.navigateByUrl('/');
            this.loginForm.reset();
          }
        }, error => {
          this.isBusy = false;
          this.submitted = false;
        });
      }
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData() {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type,
        'organisation_id': data.organisation_id,
        'organisation_name': data.organisation_name
      });
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  registerFormVisible(): void {
    this.formType = 'signIn';
  }

  loginFormVisible(): void {
    this.formType = 'login';
  }

  otp(): void {
    this.submitted = true;
    if(!this.signInForm.valid){
      return ;
    }
    if(this.submitted){
      const params: {} = {
        'organisation_name': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().organisation_name) ? this.signInForm.getRawValue().organisation_name : '',
        'name': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().name) ? this.signInForm.getRawValue().name : '',
        'email': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().email) ? this.signInForm.getRawValue().email : '',
        'contact': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().contact) ? this.signInForm.getRawValue().contact : '',
        'username': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().username) ? this.signInForm.getRawValue().username : '',
        'password': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().password) ? this.signInForm.getRawValue().username : '',
      }
      this.authService.otp(params).subscribe((data)=>{
        if(data && data.decoded_otp){
          this.signInParams = params;
          this.signInParams.decoded_otp = data.decoded_otp;
        }
      });
      this.formType = 'otpForm';
    }
  }

  signIn(): void {
    this.issubmitting =  true
    this.signInParams.otp = (this.otpForm && this.otpForm.getRawValue() && this.otpForm.getRawValue().otp) ? this.otpForm.getRawValue().otp : null;
    this.authService.register(this.signInParams).subscribe(data => {
      if (data && data.id) {
        this.globalService.setAccessToken(data.token);
        this.globalService.setSelf({
          'name': data.name,
          'email': data.email,
          'contact': data.contact,
          'user_id': data.id,
          'username': data.username,
          'user_type': data.user_type,
          'organisation_id': data.organisation_id
        });
        this.otpForm.reset();
        this.router.navigateByUrl('/');
      }
    },
      );
      setTimeout(()=>{
        this.issubmitting =false
      },3000)

  }
}
