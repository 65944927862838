import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class NotifyService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  add(data: {}): Observable<any>{
    return this.postRequest(AppUrl.NOTIFY(), data);
  }

  get(data:{}): Observable<any>{
    return this.getRequest(AppUrl.NOTIFY(), data);
  }

  detail(uniqueId: any): Observable<any>{
    return this.getRequest(AppUrl.NOTIFY(uniqueId));
  }

  count(): Observable<any>{
    return this.getRequest(AppUrl.NOTIFY_COUNT());
  }

  status(data: {}, uniqueId: any): Observable<any>{
    return this.putRequest(AppUrl.NOTIFY_STATUS(uniqueId), data);
  }

  update(data: {}, uniqueId: any): Observable<any>{
    return this.putRequest(AppUrl.NOTIFY(uniqueId), data);
  }

  delete(uniqueId: any): Observable<any>{
    return this.deleteRequest(AppUrl.NOTIFY(uniqueId));
  }

  receiveCount(): Observable<any>{
    return this.getRequest(AppUrl.NOTIFY_RECEIVE_COUNT())
  }

}
