<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-md">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-16" ngSrc="assets/img/logo/logo.png" height="2331" width="2588" alt="logo">
      </div>
    </div>
    <div class="text-center mt-4">
      <h2 class="title m-0" *ngIf="formType === 'login'">Welcome back</h2>
      <h2 class="title m-0" *ngIf="formType === 'signIn'">Welcome</h2>
      <h4 class="body-2 text-secondary m-0" *ngIf="formType === 'login'">Sign in with your credentials below.</h4>
      <h4 class="body-2 text-secondary m-0" *ngIf="formType === 'signIn'">Sign up here.</h4>
    </div>
    <form [formGroup]="loginForm" class="p-6 flex flex-col gap-4" *ngIf="formType === 'login'">
      <div class="flex flex-col">
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>Organisation</mat-label>
          <mat-select placeholder="Choose Your Organisation" formControlName="organisation_id" required>
            <mat-option *ngFor="let organisation of organisations" [value]="organisation?.id">{{ organisation?.name }}
            </mat-option>
          </mat-select>
          <small class="text-danger text-italic"
                 *ngIf="submitted && loginForm.get('organisation_id').hasError('required')">Organisation field is
            required</small>
        </mat-form-field>
        <mat-form-field class="flex-1 mt-3" appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput placeholder="Username" formControlName="username" required>
          <small class="text-danger text-italic" *ngIf="submitted && loginForm.get('username').hasError('required')">Username
            field is required</small>
        </mat-form-field>
        <mat-form-field class="flex-1 mt-3" appearance="outline">
          <mat-label>Password</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
            <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
          </button>
          <mat-hint>Click the eye to toggle visibility</mat-hint>
          <small class="text-danger text-italic" *ngIf="submitted && loginForm.get('password').hasError('required')">Password
            field is required</small>
        </mat-form-field>
      </div>
      <div class="flex items-center justify-between">
        <mat-checkbox class="caption" color="primary">Remember Me</mat-checkbox>
        <a class="caption">Forgot Password ?</a>
      </div>
      <button (click)="login()" color="primary" mat-raised-button type="button">
        Log In
      </button>
      <p class="text-secondary text-center">
        Don't have an account ?<br />
        <a>Click here to
          <button class="cursor-pointer text-red-800" (click)="registerFormVisible()"> create</button>
          one</a>
      </p>
    </form>

    <form [formGroup]="signInForm" class="p-6 flex flex-col gap-4 " *ngIf="formType === 'signIn'">
      <div class="flex flex-col">
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>Organisation Name</mat-label>
          <input matInput placeholder="Organisation name" formControlName="organisation_name" required>
          <small class="text-danger text-italic"
                 *ngIf="submitted && signInForm.get('organisation_name').hasError('required')">Username field is
            required</small>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name" required>
          <small class="text-danger text-italic" *ngIf="submitted && signInForm.get('name').hasError('required')">Username
            field is required</small>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" required>
          <small class="text-danger text-italic" *ngIf="submitted && signInForm.get('email').hasError('required')">Username
            field is required</small>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>Contact</mat-label>
          <input matInput placeholder="Contact" formControlName="contact" required>
          <small class="text-danger text-italic" *ngIf="submitted && signInForm.get('contact').hasError('required')">Username
            field is required</small>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>username</mat-label>
          <input matInput placeholder="Username" formControlName="username" required>
          <small class="text-danger text-italic" *ngIf="submitted && signInForm.get('username').hasError('required')">Username
            field is required</small>
        </mat-form-field>
        <mat-form-field class="flex-1 mt-3" appearance="outline">
          <mat-label>Password</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
            <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
          </button>
          <mat-hint>Click the eye to toggle visibility</mat-hint>
          <small class="text-danger text-italic" *ngIf="submitted && signInForm.get('password').hasError('required')">Password
            field is required</small>
        </mat-form-field>
      </div>
      <button (click)="otp()" color="primary" mat-raised-button type="button">
        SIGN UP
      </button>
      <p class="text-secondary text-center">
        Already have an account ?<br />
        <a>Click here to
          <button class="cursor-pointer text-red-800" (click)="loginFormVisible()"> login</button>
          here</a>
      </p>
    </form>

    <form [formGroup]="otpForm" class="p-6 flex flex-col gap-4" *ngIf="formType === 'otpForm'">
      <div class="flex flex-col">

        <mat-form-field class="flex-1 mt-3" appearance="outline">
          <mat-label>Enter Otp</mat-label>
          <input matInput placeholder="Otp" formControlName="otp" required>
          <small class="text-danger text-italic" *ngIf="submitted && otpForm.get('otp').hasError('required')">Otp field
            is required</small>
        </mat-form-field>
      </div>
      <button (click)="signIn()" color="primary otp-btn" mat-raised-button type="button" [disabled]="issubmitting">
        <span *ngIf="!issubmitting"> SIGN UP</span>
        <span class="loader p-2" *ngIf="issubmitting">Registration in process. Please wait for a while.......</span>
      </button>
    </form>

  </div>
</div>
