import { ChangeDetectorRef, Component } from '@angular/core';
import {VexLayoutService} from '@vex/services/vex-layout.service';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router, RouterOutlet} from '@angular/router';
import {VexConfigService} from '@vex/config/vex-config.service';
import {VexSidebarComponent} from '@vex/components/vex-sidebar/vex-sidebar.component';
import {AsyncPipe, NgIf, NgTemplateOutlet} from '@angular/common';
import {SidenavComponent} from '../components/sidenav/sidenav.component';
import {ToolbarComponent} from '../components/toolbar/toolbar.component';
import {FooterComponent} from '../components/footer/footer.component';
import {QuickpanelComponent} from '../components/quickpanel/quickpanel.component';
import {ConfigPanelToggleComponent} from '../components/config-panel/config-panel-toggle/config-panel-toggle.component';
import {ConfigPanelComponent} from '../components/config-panel/config-panel.component';
import {MatDialogModule} from '@angular/material/dialog';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';
import {MatDrawerMode, MatSidenavModule} from '@angular/material/sidenav';
import {SearchComponent} from '../components/toolbar/search/search.component';
import {VexProgressBarComponent} from '@vex/components/vex-progress-bar/vex-progress-bar.component';
import {VexConfig} from '@vex/config/vex-config.interface';
import {StorageService} from "../../shared/services/storage.service";
import {AuthService} from "../../shared/services/auth.service";
import {GlobalService} from "../../shared/services/global.service";
import {
  ToolbarNotificationsComponent
} from '../components/toolbar/toolbar-notifications/toolbar-notifications.component';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';

@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    BaseLayoutComponent,
    NgIf,
    AsyncPipe,
    SidenavComponent,
    ToolbarComponent,
    FooterComponent,
    QuickpanelComponent,
    ConfigPanelToggleComponent,
    VexSidebarComponent,
    ConfigPanelComponent,
    MatDialogModule,
    MatSidenavModule,
    NgTemplateOutlet,
    RouterOutlet,
    SearchComponent,
    VexProgressBarComponent
  ],
  providers: [
    AuthService,
    GlobalService
  ],
  standalone: true
})
export class LayoutComponent {
  config$: Observable<VexConfig> = this.configService.config$;
  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;
  sidenavDisableClose$: Observable<boolean> = this.layoutService.isDesktop$;
  sidenavFixedInViewport$: Observable<boolean> =
    this.layoutService.isDesktop$.pipe(map((isDesktop) => !isDesktop));
  sidenavMode$: Observable<MatDrawerMode> = combineLatest([
    this.layoutService.isDesktop$,
    this.configService.select((config) => config.layout)
  ]).pipe(
    map(([isDesktop, layout]) =>
      !isDesktop || layout === 'vertical' ? 'over' : 'side'
    )
  );
  sidenavOpen$: Observable<boolean> = this.layoutService.sidenavOpen$;
  configPanelOpen$: Observable<boolean> = this.layoutService.configPanelOpen$;
  quickpanelOpen$: Observable<boolean> = this.layoutService.quickpanelOpen$;

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService,
    private popover: VexPopoverService,
    private cd: ChangeDetectorRef,
  ) {
    this.reloadSelf();
  }

  reloadSelf() {
    // const test = new ToolbarNotificationsComponent(this.popover, this.cd);
    // test.notification = 10;
    // console.log(test.notification);
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type,
        'organisation_id': data.organisation_id,
        'organisation_name': data.organisation_name
      });
      return;
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }

  onSidenavClosed(): void {
    this.layoutService.closeSidenav();
  }

  onQuickpanelClosed(): void {
    this.layoutService.closeQuickpanel();
  }
}
