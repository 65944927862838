import { Component } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [
    MatGridListModule,
    FlexLayoutModule,
    MatIconModule,
    MatListModule
  ],
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  companies: any = [
    {
      text: 'Who We Are',
    },
    {
      text: 'Pricing & Plans',
    },
    {
      text: 'Services',
    },
    {
      text: 'Features',
    },
    {
      text: 'Refund Policy',
    },
  ];
  resources: any = [
    {
      text: 'FAQ’s',
    },
    {
      text: 'Blogs',
    },
    {
      text: 'Contact Us',
    },
    {
      text: 'Terms & Conditions',
    },
    {
      text: 'Privacy Statement',
    },
  ];
  contacts: any = [
    {
      url: '../assets/images/footer/phone.png',
      name: 'Call Us',
      des: '+91 9310828501'
    },
    {
      url: '../assets/images/footer/email.png',
      name: 'E-mail Us',
      des: 'support@volmint.com'
    },
  ];
  tiles = [
    { title: 'Section 1', content: 'Content for section 1' },
    { title: 'Section 2', content: 'Content for section 2' },
    { title: 'Section 3', content: 'Content for section 3' },
    { title: 'Section 4', content: 'Content for section 4' }
  ];
}
