import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService)
  }
  get(): Observable<any>{
    return this.getRequest(AppUrl.USERS());
  }
}
