import {LayoutComponent} from './layouts/layout/layout.component';
import {VexRoutes} from '@vex/interfaces/vex-route.interface';
import { content, landing } from './shared/routes/routes';
import {LoginComponent} from "./auth/login/login.component";
import { LandingComponent } from './landing/Pages/Landing/Landing.component';
import { AboutUsComponent } from './landing/Pages/about-us/about-us.component';
import { OurServicesComponent } from './landing/Pages/our-services/our-services.component';

export const appRoutes: VexRoutes = [
  {
    path: '',
    component: LayoutComponent,
    children: content
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'landing',
    component: LandingComponent,
    children: landing
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    children: landing
  },
  {
    path: 'our-services',
    component: OurServicesComponent,
    children: landing
  },
];
