import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  showErrors(error: any) {
    this.snackBar.open(error, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
      panelClass: ['background-danger']
    });
    /*if (typeof error === 'string') {
      this.popToaster('error', 'Error', error);
    } else if (error) {
      error.forEach((e: any) => {
        this.popToaster('error', e.keyName ? e.keyName : e.title, e.description);
      });
    }*/
  }

  // getConfig(): SnotifyToastConfig {
  //   this.snotifyService.setDefaults({
  //     global: {
  //       newOnTop: true,
  //       maxAtPosition: 6,
  //       maxOnScreen: 3,
  //     }
  //   });
  //   return {
  //     bodyMaxLength: 30,
  //     titleMaxLength: 15,
  //     backdrop: -1,
  //     position: SnotifyPosition.centerBottom,
  //     timeout: 5000,
  //     showProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true
  //   };
  // }

  popToaster(type: any, title = 'Error', message: any) {
    // switch (type) {
    //   case 'info':
    //     this.snotifyService.info(message, title);
    //     break;
    //   case 'error':
    //     this.snotifyService.error(message, title);
    //     break;
    //   case 'success':
    //     this.snotifyService.success(message, title);
    //     break;
    // }
  }

  constructor(
    private snackBar: MatSnackBar
  ) {

  }

  /*showSuccess(param: { title: string; message: string }) {
    this.popToaster('success', param.title, param.message);
  }*/

  showSuccess(message: string) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
      panelClass: ['background-success']
    });
  }
}
