<app-header></app-header>
<section class="banner pt-10">
  <div style="position: relative">
    <div class="py-5">
      <div class="text-center my-5">
        <div class="banner-text">
          Ensuring An
          <img src="./assets/images/banner-text-animation.gif" alt="" class="main-image-banner"><br>
          & User-Friendly Admission
        </div>
      </div>
    </div>
    <div class="">
      <img src="./assets/images/face-one.png" class="face-one " alt="" style="position: absolute">
      <img src="./assets/images/face-two.png" class="face-two " alt="" style="position: absolute">
      <img src="./assets/images/face-three.png" class="face-three " alt="" style="position: absolute">
      <img src="./assets/images/face-four.png" class="face-four " alt="" style="position: absolute">
      <img src="./assets/images/name-one.png" class="name-one " alt="" style="position: absolute">
      <img src="./assets/images/name-two.png" class="name-two " alt="" style="position: absolute">

    </div>
    <div class="flex trusted items-center justify-center  w-25 my-5 py-5 m-auto">
      <img src="./assets/images/banner-group.png" class="mb-3 mb-md-0 pr-5" alt="">
      <p class="group-text mp-0 flex items-center ">
        Trusted already by 1.2k+
      </p>
    </div>
    <div class="flex  items-center justify-center" style="gap: 16px">
      <button class="banner-btn-one" mat-flat-button type="button">
        Add Your Organization
      </button>
      <button class="banner-btn-two" mat-flat-button type="button">
        Use Enroll Free
      </button>
    </div>
    <div class="center-container" style="position: relative">
      <div class="banner-video-img">
        <img src="./assets/images/banner-video-img.png" class="img-fluid mt-5" alt="">
      </div>
    </div>
  </div>
</section>

<section class="container " style="margin-top: 250px;">
  <div class="partners">
    <p class="text-center m-5 ">
      Trusted by 10+ Institutions to scale Admissions & Sales
    </p>
    <div class="row justify-content-center mt-lg-5">
      <div class="col-lg-2 col-md-4 mb-3 col-12">
        <img src="./assets/images/coca-cola.svg.png" alt="">
      </div>
      <div class="col-lg-2 col-md-3 mb-3 col-12">
        <img src="./assets/images/modal.svg.png" alt="">
      </div>
      <div class="col-lg-2 col-md-3 mb-3 col-12">
        <img src="./assets/images/replicate.svg.png" alt="">
      </div>
      <div class="col-lg-2 col-md-3 mb-3 col-12">
        <img src="./assets/images/pallet.svg.png" alt="">
      </div>
      <div class="col-lg-2 col-md-3 mb-3 col-12">
        <img src="./assets/images/plain.svg.png" alt="">
      </div>
    </div>
  </div>
  <div class="row mt-28">
    <div class="col-md-7 mb-4 mb-md-0">
      <div class="p-5">
        <mat-card>
          <mat-card-header>
            <mat-card-title><h6 class="py-2">Integrated Lead Management</h6></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-md-8">
                <p>Automatically import leads from various sources, assign them to the right team members, and monitor
                  their
                  progress through the admissions pipeline.</p>
              </div>
            </div>
          </mat-card-content>
          <div class="mt-10">
            <img src="./assets/images/social-animation.gif" alt="" class="img-fluid" srcset="">
          </div>
        </mat-card>
      </div>
    </div>
    <div class="col-md-5 mb-4 mb-md-0">
      <div class="p-5">
        <mat-card>
          <mat-card-header>
            <mat-card-title><h6 class="py-2"> Seamless Sales Integration</h6></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p> Manage communications, track interactions, and close deals faster. All your sales data in one place!</p>
          </mat-card-content>
          <div class="flex items-center justify-center">

            <div class="seamless-inner ">
              <div class="border-one">
                <div class="border-two">
                  <div class="border-three p-2">
                    <div class="students m-auto mt-3 text-start p-3">
                      <img src="./assets/images/inner-graph-animation.gif" class="img-fluid" alt=""
                           style="border-radius: 24px">
                    </div>
                    <div class="students mb-3 mt-3">
                      <img src="./assets/images/follow-up.gif" class="img-fluid" alt="" style="border-radius: 24px">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5 mb-4 mb-md-0">
      <div class="p-5">
        <mat-card>
          <mat-card-header>
            <mat-card-title><h6 class="py-2"> Efficient Session Management</h6></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p> Our session management tools help you coordinate class times, assign instructors, and ensure that
              everything runs smoothly.</p>
          </mat-card-content>
          <div class="social-animation pt-10">
            <div class="border-one">
              <div class="border-two">
                <div class="border-three">
                  <img src="./assets/images/border.png" class="img-fluid" srcset="">
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="col-md-7 mb-4 mb-md-0">
      <div class="p-5">
        <mat-card>
          <mat-card-header>
            <mat-card-title><h6 class="py-2">Reporting & Analytics</h6></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-md-8">
                <p>Generate detailed reports on leads, admissions, financials, and more to gain insights and improve
                  your processes.</p>
              </div>
            </div>
          </mat-card-content>
          <div class="pt-32">
            <div class="flex items-center justify-center">
              <div class="reporting ">
                <div class="border-one">
                  <div class="border-two">
                    <div class="border-three p-3 pb-0">
                      <img src="./assets/images/graph-animation.gif" class="img-fluid graph-animation" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="features mt-5 pt-5">
    <div class="section-header">
      <h3 class="text-center">
        Powerful Features, Seamless Experience
      </h3>
      <p class="text-center mt-4">
        Discover how Enroll transforms your admission workflow and unlock the full potential of your admission process.
      </p>
    </div>
    <div class="feature-content mt-5">
      <mat-card>
        <div class="row p-5">
          <div class="col-md-3 active">
            <div class="d-flex align-items-center">
              <img src="./assets/images/feature-icon.png" height="13" width="13" alt="" srcset="">
              <h3 class="mp-0 ms-2">
                Application Management
              </h3>
            </div>
            <p>
              Simplify the submission, review, and approval process with an intuitive application portal.
            </p>
          </div>
          <div class="col-md-3">
            <div class="d-flex align-items-center">
              <img src="./assets/images/features-icon-dim.png" height="13" width="13" alt="" srcset="">
              <h3 class="mp-0 ms-2">
                Communication Hub
              </h3>
            </div>
            <p>
              Centralize all communications with prospective students through various channels.
            </p>
          </div>
          <div class="col-md-3">
            <div class="d-flex align-items-center">
              <img src="./assets/images/features-icon-dim.png" height="13" width="13" alt="" srcset="">
              <h3 class="mp-0 ms-2">
                Analytics and Reporting
              </h3>
            </div>
            <p>
              Gain actionable insights with advanced analytics and customizable reports.
            </p>
          </div>
          <div class="col-md-3">
            <div class="d-flex align-items-center">
              <img src="./assets/images/features-icon-dim.png" height="13" width="13" alt="" srcset="">
              <h3 class="mp-0 ms-2">
                Social Media Leads
              </h3>
            </div>
            <p>
              Simplify the submission, review, and approval process with an intuitive application portal.
            </p>
          </div>
        </div>
        <div class="flex items-center justify-center p-20">
          <div class="feature-img-div">
            <div class="border-one">
              <div class="border-two ">
                <img src="./assets/images/feature-img-div-one.png" class="img-fluid" alt="">
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</section>

<section class="software-free m-0 p-0 mt-5 py-3">
  <div class="container">
    <div class="section-heading p-5">
      <h3 class="text-center">
        Get Our Software For Free, <span>NOW!</span>
      </h3>
      <p class="text-center mt-3">
         See Enroll in action and discover its full potential
      </p>
    </div>
    <div class="inner-div">
      <div class="inner-child p-5 pb-0">
        <div class="row">
          <div class="col-md-6 position-relative" style="height: 500px">
            <div class="inner-left position-absolute">
              <div class="inner-two py-4">
                <img src="./assets/images/inner-left-img.png" class="img-fluid" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-6 py-10 p-10">
            <div class="row">
                <p>
                  The system for managing admissions oversees the gathering of student applications and documentation,
                  and shortlists applicants for online processing.
                </p>
            </div>
            <ul class="list-unstyled">
              <li>
                <div class="flex align-items-center">
                  <img src="./assets/images/list-bullet.png" alt="" srcset="">
                  <p class="mp-0 ms-2">
                    User-Friendly Interface
                  </p>
                </div>
              </li>
              <li>
                <div class="flex align-items-center">
                  <img src="./assets/images/list-bullet.png" alt="" srcset="">
                  <p class="mp-0 ms-2">
                    Automated Online Software System
                  </p>
                </div>
              </li>
              <li>
                <div class="flex align-items-center">
                  <img src="./assets/images/list-bullet.png" alt="" srcset="">
                  <p class="mp-0 ms-2">
                    Get Real-Time Report
                  </p>
                </div>
              </li>
              <li>
                <div class="flex align-items-center">
                  <img src="./assets/images/list-bullet.png" alt="" srcset="">
                  <p class="mp-0 ms-2">
                    Accurate and Reliable
                    <Data></Data>
                  </p>
                </div>
              </li>
            </ul>

            <button class="flex mt-5 items-center justify-center">
              Use For Free
              <img src="./assets/images/tilted-arrow.png" width="34" height="27" class="ms-2" alt="" srcset="">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="below-inner m-auto  my-10 p-5 position-relative">
      <h3 class="text-center mb-5">
        Discover the Power of Enroll’s AMS Software Modules
      </h3>

      <div class="row discover-row">
        <div class="col-md-4 p-3 discover-child child-one">
          <div class="flex items-center justify-center">
            <img src="./assets/images/manage.png" alt="">
            <div class="ms-4">
              <p class="mp-0 discover-inner-title">
                Manage & Regulate Session
              </p>
              <p class="mp-0 mt-3 flex inner-link-yellow">
                Learn More
                <img src="./assets/images/right-yellow.png" class="ms-3" alt="">
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 p-3 discover-child child-two">
          <div class="flex items-center justify-center">
            <img src="./assets/images/manage-two.png" class="" alt="">
            <div class="ms-4">
              <p class="mp-0 discover-inner-title">
                Manage & Regulate Session
              </p>
              <p class="mp-0 mt-3 flex inner-link-yellow inner-link-blue">
                Learn More
                <img src="./assets/images/blue-arrow.png" class="ms-3" alt="">
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 p-3 discover-child child-three">
          <div class="flex items-center justify-center">
            <img src="./assets/images/manage-three.png" alt="">
            <div class="ms-4">
              <p class="mp-0 discover-inner-title">
                Manage & Regulate Session
              </p>
              <p class="mp-0 flex mt-3 inner-link-yellow inner-link-red">
                Learn More
                <img src="./assets/images/red-arrow.png" class="ms-3" alt="">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-5 few-steps">
  <div class="section-heading p-5">
    <h3 class="text-center">
      Few Easy Steps and Done
    </h3>
    <p class="text-center mt-3">
      Help users discover and understand your product’s value across their entire journey, from new user to power
      user.
    </p>
  </div>
  <ul class="flex items-center justify-center m-auto list-unstyled p-2  ">
    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab label="1. Add Your Organisation">
        <div class="free-steps-img mt-5 flex items-center justify-center">
          <img src="./assets/images/add-organization-stepper.png" class="img-fluid" alt="">
        </div>
      </mat-tab>
      <mat-tab label="2. Verify">
        <div class="free-steps-img mt-5 flex items-center justify-center">
          <img src="./assets/images/Verify-stepper.png" class="img-fluid" alt="">
        </div>
      </mat-tab>
      <mat-tab label="3. Choose Plan">
        <div class="free-steps-img mt-5 flex items-center justify-center">
          <img src="./assets/images/choos-%20plan-stepper.png" class="img-fluid" alt="">
        </div>
      </mat-tab>
      <mat-tab label="4. Explore Dashboard">
        <div class="free-steps-img mt-5 flex items-center justify-center">
          <img src="./assets/images/explore-dashboard-stepper.png" class="img-fluid" alt="">
        </div>
      </mat-tab>
    </mat-tab-group>
  </ul>
  <div class="complimentry-offerings p-5">
    <div class="container">
      <h3>
        Complimentary offerings from Us
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="complimentry-left mt-5">
            <div class="row flex items-center justify-center">
              <div class="col-md-6 col-lg-4 mb-4">
                <div class="complimentry-inner p-4 p-md-4">
                  <img class="centered-image" src="./assets/images/comp-inner-one.png" alt="">
                  <p class="mp-0 mt-3 text-center">
                    Lifetime Free Maintenance
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <div class="complimentry-inner p-4 p-md-4">
                  <img src="./assets/images/comp-inner-two.png" alt="" class="centered-image">
                  <p class="mp-0 mt-3 text-center">
                    Updates and Upgrades
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <div class="complimentry-inner p-4 p-md-4">
                  <img src="./assets/images/comp-inner-three.png" alt="" class="centered-image">
                  <p class="mp-0 mt-3 text-center">
                    Free Server
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <div class="complimentry-inner p-4 p-md-4">
                  <img src="./assets/images/comp-inner-four.png" alt="" class="centered-image">
                  <p class="mp-0 mt-3 text-center">
                    Personalized Assistance
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <div class="complimentry-inner p-4 p-md-4">
                  <img src="./assets/images/comp-inner-five.png" alt="" class="centered-image">
                  <p class="mp-0 mt-3 text-center">
                    Resolution within 48 hours
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-4">
                <div class="complimentry-inner p-4 p-md-4">
                  <img src="./assets/images/comp-inner-six.png" alt="" class="centered-image">
                  <p class="mp-0 mt-3 text-center">
                    Discounts on Extra Modules
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 p-5">
          <div class="free-gift overflow-hidden m-auto">
            <img src="./assets/images/free-gift.png" class="img-fluid m-auto" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-15px few-steps">
  <div class="section-heading p-5">
    <h3 class="text-center">
      Frequently Asked Questions
    </h3>
    <p class="text-center mt-3">
      Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
      most promising candidates first.
    </p>
  </div>
  <div class="text-left mt-10px max-w-3xl mx-auto">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Does this app offer a free trial period?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>What payment methods do you offer?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>How much does a subscription cost?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>What is your refund policy?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Let AI analyze and rank applicants based on qualifications, experience, and skills, ensuring you focus on the
          most promising candidates first.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<section class="mt-5 few-steps">
  <div class="container">
    <div class="section-heading p-5">
      <h3 class="text-center">
        Take a look at our latest Blogs
      </h3>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12 mb-2">
        <mat-card>
          <img src="./assets/images/card-one.png" class="card-img-top img-fluid" alt="...">
          <mat-card-header class="my-2">
            <mat-card-title>Unending limits when it comes</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Class apten tacit socios litora amter torqu Class apten tacit socios litora amter
              torqu</p>
          </mat-card-content>
          <span class="blog-date p-2" style="background: #FFF0FD;">Dec 28, 2022</span>
        </mat-card>
      </div>
      <div class="col-md-4 col-sm-12 mb-2">
        <mat-card>
          <img src="./assets/images/card-two.png" class="card-img-top img-fluid" alt="...">
          <mat-card-header class="my-2">
            <mat-card-title>Unending limits when it comes</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Class apten tacit socios litora amter torqu Class apten tacit socios litora amter
              torqu</p>
          </mat-card-content>
          <span class="blog-date p-2" style="background: #FFF0FD;">Dec 28, 2022</span>
        </mat-card>
      </div>
      <div class="col-md-4 col-sm-12 mb-2">
        <mat-card>
          <img src="./assets/images/card-three.png" class="card-img-top img-fluid" alt="...">
          <mat-card-header class="my-2">
            <mat-card-title>Unending limits when it comes</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Class apten tacit socios litora amter torqu Class apten tacit socios litora amter
              torqu</p>
          </mat-card-content>
          <span class="blog-date p-2" style="background: #FFF0FD;">Dec 28, 2022</span>
        </mat-card>
      </div>
    </div>
  </div>

</section>


<section class="above-footer mt-5 py-5 p-lg-5">
  <div class="container">
    <div class="inner-container m-auto text-center">
      <img src="./assets/images/img-group.png" alt="" class="mx-auto">
      <h3 class="mt-5">
        Need any further assistance?
      </h3>
      <p class="mt-4">
        Feel free to reach out for any inquiries or assistance.
      </p>

      <button type="button" class="mt-5">
        Book an appointment now
      </button>
    </div>
  </div>
</section>
<app-footer></app-footer>
